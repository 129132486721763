.jump {
  display: inline-block;
}
.jump span {
  float: left;
  position: relative;
}

.jump span:nth-child(1) {
  -webkit-animation: jump 1s linear 0s infinite alternate;
}

.jump span:nth-child(2) {
  -webkit-animation: jump 1s linear 0.2s infinite alternate;
}

.jump span:nth-child(3) {
  -webkit-animation: jump 1s linear 0.4s infinite alternate;
}

.jump span:nth-child(4) {
  -webkit-animation: jump 1s linear 0.6s infinite alternate;
}

.jump span:nth-child(5) {
  -webkit-animation: jump 1s linear 0.8s infinite alternate;
}

@keyframes jump {
  0% {
    top: 0px;
    color: hsl(224, 85%, 66%);
  }
  50% {
    top: -3px;
    color: hsl(134, 85%, 66%);
  }
  100% {
    top: 3px;
    color: hsl(44, 85%, 66%);
  }
}

#titles {
  position: absolute;
  width: calc(100% / 3 - 24px);
  height: 100%;
  bottom: 0;
  text-align: justify;
  overflow: hidden;
  -webkit-transform-origin: 0% 100%;
  -moz-transform-origin: 0% 100%;
  -ms-transform-origin: 0% 100%;
  -o-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
}

#titles p {
  text-align: justify;
}

#titlecontent {
  position: absolute;
  top: 100%;
  width: 100%;
  /* -ms-animation-iteration-count: infinite; */
  -webkit-animation: scroll 30s linear 4s forwards;
  -moz-animation: scroll 30s linear 4s forwards;
  -ms-animation: scroll 30s linear 4s forwards;
  -o-animation: scroll 30s linear 4s forwards;
  animation: scroll 30s linear 4s forwards;
  /* animation-iteration-count: infinite; */
}

/* animation */
@-webkit-keyframes scroll {
  0% {
    top: 100%;
  }
  100% {
    top: -100%;
  }
}

@-moz-keyframes scroll {
  0% {
    top: 100%;
  }
  100% {
    top: -100%;
  }
}

@-ms-keyframes scroll {
  0% {
    top: 100%;
  }
  100% {
    top: -100%;
  }
}

@-o-keyframes scroll {
  0% {
    top: 100%;
  }
  100% {
    top: -100%;
  }
}

@keyframes scroll {
  0% {
    top: 100%;
  }
  100% {
    top: -100%;
  }
}
