.react-datepicker__input-container input {
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  background-color: transparent;
  border: 1px solid #d6b88a;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  outline: none;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input::placeholder {
  color: #9ca3af6c;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #272b37;
  color: #000;
  border: 0px;
  border-radius: 0;
  display: inline-block;
  position: relative;
}

.react-datepicker__header {
  text-align: center;
  background-color: #272b37;
  /* border-bottom: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; */
  padding-top: 8px;
  position: relative;
}

.react-datepicker__today-button {
  background: #272b37;
  border-top: 0px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}
.react-datepicker__day {
  color: #fef8e0;
}
.react-datepicker__day--disabled {
  color: #7c7e86;
}

.react-datepicker__day:hover {
  background-color: #000000;
}

.react-datepicker__day--keyboard-selected {
  background-color: #216ba5;
}

.react-datepicker__day-name,
.react-datepicker__current-month {
  color: #eaebec;
}
