@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "SVN-Gotham";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace, 'SVN-Gotham', 'Be-Vietnam-Pro';
}

@layer components {
  .btn {
    @apply px-2 py-0.5 hover:bg-opacity-90;
  }
  .btn-active {
    @apply bg-green-600 dark:bg-blue-500 text-white uppercase;
  }
  .btn-inactive {
    @apply bg-blue-100 text-gray-500 uppercase;
  }
}

@layer utilities {
  .full-viewport {
    @apply flex flex-col w-full;
    height: 100vh;
    overflow: auto;
  }
  .bg-light {
    @apply bg-white;
  }
  .bg-dark {
    @apply bg-gray-700;
  }
}

@font-face {
  font-family: "SVN-Gotham";
  src: url("./assets/fonts/SVN-Gotham\ Rounded\ Bold.otf") format("truetype");
}

@font-face {
  font-family: 'Be-Vietnam-Pro';
  src: url('./assets/fonts/BeVietnamPro-SemiBold.ttf') format('truetype');
}


.event-title{
  font-family: 'SVN-Gotham';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 25px;

color: #FFF2BC;
}

.event-prize-title{
  font-family: 'Be Vietnam Pro';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;
color: #9C7F5C;
}

.event-prize{

  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: #DBB487;
}

.event-rule{
  font-family: 'Be Vietnam Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
color: #A2A2A2;
}

.container{
	width:500px;
	height:500px;
	background-color: #ccc;
	border-radius:50%;
	border:15px solid #dde;
	position: relative;
	overflow: hidden;
	transition: 5s;
}

.container div{
	height:50%;
	width:200px;
	position: absolute;
	clip-path: polygon(100% 0 , 50% 100% , 0 0 );
	transform:translateX(-50%);
	transform-origin:bottom;
	text-align:center;
	display:flex;
	align-items: center;
	justify-content: center;
	font-size:20px;
	font-weight:bold;
	font-family:sans-serif;
	color:#fff;
	left:135px;
}

.container .one{
	background-color: #3f51b5;
	left:50%;
}
.container .two{
	background-color: #ff9800;
	transform: rotate(45deg);
}
.container .three{
	background-color: #e91e63;
	transform:rotate(90deg);
}
.container .four{
	background-color: #4caf50;
	transform: rotate(135deg);
}
.container .five{
	background-color: #009688;
	transform: rotate(180deg);
}
.container .six{
	background-color: #795548;
	transform: rotate(225deg);
}
.container .seven{
	background-color: #9c27b0;
	transform: rotate(270deg);
}
.container .eight{
	background-color: #f44336;
	transform: rotate(315deg);
}

.arrow{
	position: absolute;
	top:0;
	left:50%;
	transform: translateX(-50%);
	color:#fff;
}

.arrow::before{
	content:"\1F817";
	font-size:50px;
}

#spin{
	position: absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	z-index:10;
	background-color: #e2e2e2;
	text-transform: uppercase;
	border:8px solid #fff;
	font-weight:bold;
	font-size:20px;
	color:#a2a2a2;
	width: 80px;
	height:80px;
	font-family: sans-serif;
	border-radius:50%;
	cursor: pointer;
	outline:none;
	letter-spacing: 1px;
}
