@keyframes up-down {
  0% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
  40% {
    transform: translate3d(0, 0.1%, 0) scale3d(1, 0.9, 1);
  }
  100% {
    transform: translate3d(0, 0.12%, 10) scale3d(1.1, 0.9, 1.1);
  }
}
.up-down {
  animation: up-down 4s linear alternate infinite;
  /* animation: animate 1s linear infinite; */
}

@keyframes animate {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

/* img.up-down {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
img.up-down {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
} */
